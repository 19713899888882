export const gettingStartedForNewUsers = {
  order: 0,
  name: "Getting started for new Tower Hunt users",
  subcategory: "Getting started for new users",
  markdown: `# Getting started for new Tower Hunt users
  A Tower Hunt account is your ticket to a personalized view of the markets you care about, and an opportunity to earn money in a new way in real estate. If you've recently joined, this guide will help you get set up in Tower Hunt so you can get started. Before you dive in, make sure you're able to {% inlineRouterLink %}sign in to your account{% /inlineRouterLink %}.

  ## 1. Explore a market

  The {% inlineRouterLink %}map{% /inlineRouterLink %} helps you find what you need quickly. When you zoom to a local level, bright openings called {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %} appear. These teach Tower Hunt which locations matter to you, triggering various personalized features.

  {% tablessContainer %}
  1. Select the {% inlineRouterLink %}search bar{% /inlineRouterLink %} and start typing a location of interest (ex. "Boston, MA").
  2. Select a {% inlineAppIcon iconName="marker" %}**place search result**{% /inlineAppIcon %}.
  3. After the map recenters, keep zooming in until an {% inlineRouterLink articleId="explored-areas" %}explored area{% /inlineRouterLink %} appears.
  ![Explored area screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## 2. Triage news

  In explored areas that intersect with Tower Hunt's public database, you'll begin receiving {% inlineRouterLink %}news{% /inlineRouterLink %}. Some news may include datapoints that you want.

  {% tablessContainer %}
  1. When news is available, the Tower Hunt logo will pulse in the upper-left. Click the logo or slide to the right where you see "Swipe for news."
  2. News and other notifications appear as a stack of cards in the {% inlineAppIcon iconName="news" %}{% inlineRouterLink %}**News**{% /inlineRouterLink %}{% /inlineAppIcon %} section of the {% inlineRouterLink %}Me tab{% /inlineRouterLink %}.
  3. Swipe right or {% inlineAppIcon iconName="star" %}**save**{% /inlineAppIcon %} news that you want to revisit. When you do this, a new {% inlineRouterLink %}task{% /inlineRouterLink %} is created in your "News" {% inlineRouterLink %}task list{% /inlineRouterLink %} and the news card is attached to the task.
  4. Swipe left or {% inlineAppIcon iconName="xMark" %}**discard**{% /inlineAppIcon %} news that isn't important to you.
  5. Many news events involve paid {% inlineRouterLink %}datapoints{% /inlineRouterLink %}. {% inlineAppIcon iconName="unlock" %}**Unlock**{% /inlineAppIcon %} them to reveal details if the card seems relevant.
  ![News cards screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  Visit {% inlineRouterLink %}News card types{% /inlineRouterLink %} to learn about all of the available card types in Tower Hunt.

  ## 3. Unlock datapoints you need

  Certain datapoints are freely accessible. Others must be licensed. Every datapoint displays information about its pricing and quality. Unlocking the data you need is done via a swipe.

  {% tablessContainer %}
  1. Search for "200 Clarendon Street, Boston, MA" using the search bar.
  2. Select the {% inlineAppIcon iconName="buildings" %}**200 Clarendon Street property result**{% /inlineAppIcon %}.
  3. Click the {% inlineAppIcon iconName="lock" %}**Unlock data button**{% /inlineAppIcon %} at the bottom-center of the page to view the types of available datapoints.
  4. Slide right to unlock datapoints of interest.
  ![Explored area screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  Visit {% inlineRouterLink %}Unlocking datapoints{% /inlineRouterLink %} to learn about Tower Hunt's pay-as-you-go pricing approach.

  ## 4. Learn about other Tower Hunt features

  Now that you're set up in Tower Hunt, you're ready to get started and explore other features.

  - Visit {% inlineRouterLink articleId="how-to-use-tower-hunt-your-quick-start-guide" %}How to use Tower Hunt: your quick start guide{% /inlineRouterLink %} for an overview of the basics.
  - Explore our {% inlineRouterLink %}Tower Hunt 101{% /inlineRouterLink %} and {% inlineRouterLink %}video tutorials{% /inlineRouterLink %} to learn how to work in Tower Hunt.
  - Click the {% inlineAppIcon iconName="questionMark" %}**question mark icon**{% /inlineAppIcon %} to open **Help** in Tower Hunt and find answers quickly.
  `,
};
